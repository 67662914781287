.slot-picker--dropdown {
  z-index: 100;
  background: #fff;
}

.slot-picker--toggleButton {
  background: #fff;
  border: none;
}

.slot-picker--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: bold;
  button {
    border: none;
  }
}

.slot-picker--grid.days-of-week {
  font-weight: bold;
}

.form-field {
  padding: 0.5em;
  width: 30em;

  display: flex;
  flex-flow: row nowrap;

  & > input {
    flex: 0 1 100%;
  }
}

.slot-picker {
  position: relative;

  &--dropdown {
    //    position: absolute;
    top: 100%;
    right: 0;
    display: none;

    &.show {
      display: block;
    }
  }

  &--grid,
  &--grid-days {
    display: grid;
    grid-template-columns: repeat(7, 14.28%);
  }

  &--grid {
    &.slot-picker--time {
      display: grid;
      grid-template-columns: repeat(5, 20%);
      padding-bottom: 0.5rem;
    }
  }

  &--cell {
    text-align: center;
    padding: 0.5em;
  }
  &--cell a {
    text-decoration: none;
    cursor: pointer;
  }

  &--cell.active {
    color: var(--brandColor);
    font-weight: bolder;
    background-color: #f1f1f1;
  }

  &--cell:first-child {
    grid-column: var(--month-offset);
  }

  &--morning-title,
  &--afternoon-title,
  &--evening-title,
  &--night-title {
    display: block;
    font-weight: bold;
    padding: 0.5rem;
  }
}

.slot-picker--container {
  .switch {
    height: 22px;
    width: 40px;
  }

  .slider:before {
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 3px;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
}
.penone {
  pointer-events: none;
}
