.onboarding_show,
.onboarding_get_started,
.onboarding_update {
  background-color: #ffffff;
  .btn-primary {
    background: #2d356b !important;
    color: white;
    border: none;
    box-shadow: none;
    border-radius: 4px;
  }
  .text-primary,
  .btn-outline-primary {
    color: #2d356b !important;
  }
  .btn-outline-primary {
    border-color: #2d356b !important;
  }
  .btn-outline-primary:hover {
    background: #2d356b !important;
    color: white !important;
  }
}
.top {
  height: 100px;
  overflow: hidden;
}

.middle {
  height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
  &.widget-wrapper {
    height: calc(100vh - 100px);
  }
}
.bottom {
  height: 100px;
}

#get_started {
  .right_container {
    .get_started_item {
      width: 400px;
      position: relative;
      background: #f8f8ff;
      border: 1px solid #8f96c6;
      box-sizing: border-box;
      border-radius: 8px;
      margin-bottom: 40px;
      height: 56px;
      p {
        margin-bottom: 0;
      }
      .number {
        width: 32px;
        height: 32px;
        border: 1px solid map-get($gs-primary-pallet, 400);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: map-get($gs-primary-pallet, 400);
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        left: -14px;
      }
    }
  }
}

.onboarding_show,
.onboarding_update {
  font-family: Roboto !important;
  overflow: hidden;
  .progressbar {
    counter-reset: step;
    padding-left: 0;
    margin-left: -25px;
  }
  .progressbar li {
    list-style-type: none;
    width: 150px;
    float: left;
    font-size: 14px;
    position: relative;
    text-align: center;
    color: #545ea3;
    &.current {
      font-weight: 500;
    }
    &.active {
      a {
        color: #3a3a3a !important;
      }
    }
  }
  .progressbar li:before {
    width: 36px;
    height: 36px;
    content: counter(step);
    counter-increment: step;
    line-height: 36px;
    border: 1px solid #545ea3;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
    font-weight: bold;
  }
  .current::before {
    background-color: #545ea3 !important;
    color: white;
  }
  .progressbar li:after {
    width: 100%;
    content: '';
    position: absolute;
    top: 20px;
    left: -50%;
    // z-index: -1;
    border-top: 2px dashed #a3a3a3;
  }
  .progressbar li:first-child:after {
    content: none;
  }
  .progressbar li.unfinished a {
    color: #3a3a3a;
  }
  .progressbar li.active,
  .progressbar li.active a,
  .progressbar li.current a {
    color: #545ea3;
  }
  .progressbar li.active:before {
    border-color: #51a34a;
    background-color: #51a34a;
    color: #ffffff;
    content: '✓';
  }

  .bg-gradient {
    background: rgb(61, 56, 133);
    background: linear-gradient(
      90deg,
      rgba(61, 56, 133, 1) 0%,
      rgba(62, 57, 130, 1) 26%,
      rgba(49, 46, 108, 1) 89%
    );
  }
  .link {
    color: #545ea3;
    text-decoration: underline;
    :hover {
      color: #545ea3;
    }
  }

  .invite-team {
    .table-wrapper {
      border: 1px solid #e0e0e0;
      border-radius: 8px;
    }
    .table-heading {
      label {
        margin-bottom: 0;
        font-weight: 500;
        font-size: 16px;
        color: #3a3a3a;
        line-height: 18px;
      }
      background: #fafafa;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
    }
  }

  .video-tour {
    .video-section {
      position: relative;
      height: 100%;
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fef2e4;
        display: flex;
        align-items: center;
        justify-content: center;
        .play_button {
          position: absolute;
          cursor: pointer;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    overflow-y: auto;
    .middle {
      height: auto;
    }
    .onboarding-actions {
      margin-top: 50px;
      background: white;
      flex-direction: column-reverse;
      i {
        display: none !important;
      }
      .prev {
        border: none;
        color: #666 !important;
        font-weight: 500 !important;
        margin-top: 20px;
        margin-bottom: 5px;
      }
    }
    .video-tour {
      .middle {
        padding-top: 20px;
      }
      .video-section {
        .logo {
          width: 189px;
        }
        .play_button {
          width: 36px;
          height: 36px;
        }
        width: 328px;
        height: 244px !important;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.13);
        border-radius: 4px;
        margin-top: 20px;
        margin-bottom: 50px;
      }
    }
  }
}

.integration-variant {
  background: rgba(233, 235, 250, 0.2);
  border: 1px solid rgba(84, 94, 163, 0.3);
  i {
    color: #545ea3;
  }
  .icon {
    position: relative;
    top: 5px;
  }
  img {
    margin: 0 15px;
  }
}

.copy_time {
  right: 5% !important;
}

.office_hour_row {
  justify-content: center;
}

label.is-invalid {
  margin: 0;
  color: crimson;
}
