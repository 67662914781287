$theme-header-bg: #eef0f7;
$main-bg: white;
$white: white;

.pages_account {
  .card {
    height: 520px;
    .card-img-top {
      height: 280px;
      width: 100%;
      &.fallback-0 {
        background: repeating-radial-gradient(circle at bottom left, $colors_1),
          repeating-radial-gradient(circle at bottom right, $colors_1);
        background-blend-mode: overlay;
      }
      &.fallback-1 {
        background: linear-gradient(90deg, #fff 0.25em, transparent 0),
          linear-gradient(90deg, #364c88 50%, transparent 0),
          linear-gradient(90deg, #e0d81d 50%, #0a226a 0);
        background-size: 0.25 * 9em, 0.5 * 9em, 9em;
      }
      &.fallback-2 {
        background-color: #269;
        background-image: linear-gradient(
            rgba(255, 255, 255, 0.5) 2px,
            transparent 2px
          ),
          linear-gradient(90deg, rgba(255, 255, 255, 0.5) 2px, transparent 2px),
          linear-gradient(rgba(255, 255, 255, 0.28) 1px, transparent 1px),
          linear-gradient(90deg, rgba(255, 255, 255, 0.28) 1px, transparent 1px);
        background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
        background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
      }
    }
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .pad-content {
    padding: 1.5rem 4rem !important;
  }

  .profile-header {
    transform: translateY(240px);
  }

  .bg-wrapper {
    height: 380px;
    //		background-image: linear-gradient(to top, rgba(0, 0, 0, 0.82), rgba(255, 255, 255, 0.01)), url(https://images.unsplash.com/photo-1575052814086-f385e2e2ad1b?ixlib=rb-1.2.1&auto=format&fit=crop&w=2850&q=80);
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bordered {
    border: 1px solid #ece9e9;
  }

  .share-icons {
  }

  .share-icons svg,
  .share-icons .btn {
    color: #a4b0be;
    height: 1.4rem;
    margin-right: 1rem;
    overflow: hidden;
    vertical-align: middle;
  }
  .share-icons svg:hover,
  .share-icons .btn:hover {
    color: #007bff;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .animated,
  .btn {
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }

  @media only screen and (max-width: 768px) {
    .pad-content {
      padding: 1.5rem !important;
    }
  }
}

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}
.gallery .animation {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

@media (max-width: 450px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
  }
}

@media (max-width: 400px) {
  .btn.filter {
    padding-left: 1.1rem;
    padding-right: 1.1rem;
  }
}
