.templates-list-container,
.editable {
  // padding: 1.5em;
  p {
    margin-bottom: 0.5em;
  }

  h4,
  h5,
  h3 {
    margin-top: 0.5em;
  }

  h2,
  h1 {
    margin-top: 0.75em;
  }
}

.editor-wrapper {
  border: 1px solid #ccc;
}

.templates-list-container {
  h5 {
    font-size: 0.8em;
    text-transform: uppercase;
  }
}

.note-time {
  padding: 1.5em;
  font-size: 1em;
  color: #21164a;
  float: right;
}

.templates-list {
  li {
    margin-top: 10px;

    i {
      font-size: 1.2em;
      margin-right: 10px;
    }

    color: #e3e0f7;

    &:active,
    &:focus,
    &:hover {
      color: #21164a;

      a,
      a:active,
      a:focus,
      a:hover {
        color: #21164a;
      }
    }

    a {
      color: #e3e0f7;
    }
  }
}

#bio-collapse:blank,
#bio-collapse:blank + a {
  display: none;
  height: 0;
  margin: 0;
  overflow: hidden;
}

#bio {
  font-size: 1rem;
  line-height: 1.5;
}

#bio #bio-collapse.collapse:not(.show) {
  display: block;
  height: 6rem;
  overflow: hidden;
}

#bio #bio-collapse.collapsing {
  height: 6rem;
}

#bio a.collapsed:after {
  content: '+ Show More';
}

#bio a:not(.collapsed):after {
  content: '- Show Less';
}

.iti.iti--allow-dropdown {
  width: 100%;
}

.widget-content {
  .badge-warning {
    min-height: 48px;
    background: #fff6ed;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 32px;
  }

  .available-block {
    // min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    .images {
      display: flex;
      align-items: center;
      margin: 0 1rem;

      img {
        width: 60px;
        height: 60px;
        border: 1px solid #ffffff;
        border-radius: 100%;
        object-fit: cover;
      }

      .image:nth-child(2),
      .image:nth-child(3) {
        margin-left: -0.5rem;
      }
    }

    p {
      margin-bottom: 0;
    }

    padding: 0.6rem 0.2rem;
  }
}

#choose_widget_wrapper {
  .launch_type {
    background: #ffffff;
    border: 1px solid #545ea3;
    box-sizing: border-box;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    cursor: pointer;

    .header {
      background: #dbdef2;
      height: 60px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      position: relative;

      p {
        color: #37407a;
        font-size: 18px;
        font-weight: 500;
      }

      .recommended {
        position: absolute;
        right: 0;
        top: 0;
      }

      .radio-circle {
        width: 28px;
        height: 28px;
        background: #ffffff;
        border: 1px solid #545ea3;
        border-radius: 100%;
      }
    }

    &[data-active='true'] {
      .header {
        background: #37407a;

        p {
          color: white;
        }
      }

      .radio-circle {
        background: #37407a;
        border: 1px solid #ffffff;

        .inner-radio {
          width: 16px;
          height: 16px;
          border-radius: 100%;
          background: #fefefe;
        }
      }
    }
  }

  .inner-content {
    height: 350px;
  }

  .bubble_container,
  .card_container {
    .launch_type {
      .header {
        background: #fef2e4 !important;

        p {
          color: #3a3a3a;
          font-size: 16px;
        }
      }
    }

    .card_mockup {
      background: #ffffff;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.36);
      border-radius: 8px;
      width: 264px;
      min-height: 140px;
      padding: 16px;

      .buttonCard {
        display: flex;
        align-items: center;
        min-width: 132px;
        height: 32px;
        background: #6658d3;
        border-radius: 4px;
        color: #ffffff;

        span {
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
          margin-right: 32px;
          margin-left: 12px;
        }

        i {
          font-size: 16px;
        }
      }
    }

    .bubble_text {
      position: relative;
      background: #37407a;
      padding: 0.2rem 1rem;
      border-radius: 4px;
      box-shadow: 0px 2.8141px 5.62819px rgba(0, 0, 0, 0.08);
      margin-bottom: 5px;

      &::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: #37407a transparent transparent transparent;
      }
    }

    #bubble_logo {
      width: 70px;
      height: 70px;
      border-radius: 100%;
      object-fit: cover;
      box-shadow: 0 0 5px #dedede;
    }
  }

  .form-control {
    background: #ffffff;
    border: 1px solid #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 32px;
  }

  .form-group-gray {
    background: #fafafa;
    border: 1px solid #f6f6f6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 20px;
  }

  .widget_image {
    background: #f6f6f6;
    border: 1px dashed #e0e0e0;
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
  }

  .back_step {
    background: #f6f6f6;
    border-radius: 4px;
    cursor: pointer;
    padding: 0 1rem;

    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #2d356b;
    }
  }

  [data-display='false'] {
    display: none;
  }

  .card-fields {
    background: #fafafa;
    border: 1px solid #f6f6f6;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #3a3a3a;
    }

    .form-control {
      background: #ffffff;
      border: 1px solid #e0e0e0;
      box-sizing: border-box;
      border-radius: 4px;
      margin-bottom: 32px;
    }
  }

  .noBotsText {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    svg {
      width: 20px;
      height: 20px;
    }

    .text {
      padding: 5px;
      background: #fce7f3;
      border-radius: 2px;
      margin-bottom: 0;
      margin-left: 8px;

      p {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        color: #9d174d;
      }
    }
  }

  .advance_settings {
    .settings-toggle {
      color: #545ea3;
      position: relative;

      &:hover {
        text-decoration: none;
      }

      &[data-toggle='collapse']:after {
        font-family: 'Material Icons';
        content: '\e5ce';
        position: absolute;
        color: #545ea3;
        font-size: 25px;
        line-height: 22px;
        right: -25px;
        top: 0;
      }

      &[data-toggle='collapse'].collapsed:after {
        content: '\e5cf';
      }
    }

    .delay {
      position: relative;
      display: inline-block;

      .form-control {
        width: 100px;
      }

      p {
        position: absolute;
        top: 5px;
        right: 10px;
        z-index: 1000;
      }
    }
  }
}

.code-area {
  background: #fff;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;

  code {
    background: white;
    padding-top: 1rem;
  }

  pre {
    margin-bottom: 0;
    font-size: inherit;
  }
}

[data-audio-mode='true'] {
  .hide {
    display: none;
  }

  .un-hide {
    display: block !important;
  }
}

.widget-permissions {
  .switch-toggle {
    border-radius: 4px;

    .item {
      position: relative;
      height: 40px;
      background: #ffffff;
      border: 1px solid #a3a3a3;

      i {
        color: #666;
        margin-right: 6px;
      }

      p {
        display: flex;
        align-items: center;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      &[data-active='true'] {
        border: 1px solid var(--brandColor);
        background: var(--brandTransparent);

        p,
        i {
          color: var(--brandColor) !important;
          font-weight: bold;
        }
      }
    }

    .item.active::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--brandColor);
      opacity: 0.1;
    }
  }

  button {
    &:disabled,
    &.disabled {
      opacity: 0.5 !important;
    }
  }

  .widget-permissions-wrapper {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      color: #666;
      margin-bottom: 0rem;
    }

    i {
      color: var(--brandColor);
    }
  }
}

#instructionsAccordion {
  .accordion-heading {
    @extend .text-dull;
    @extend .fs-16;
    @extend .font-weight-700;
    width: 100%;
    text-align: left;
    padding: 1rem;

    &:hover,
    &:focus {
      text-decoration: none;
      @extend .text-dull;
    }
  }

  .accordion-heading[data-toggle='collapse']:after {
    font-family: 'Material Icons';
    content: '\e5ce';
    position: absolute;
    color: map-get($gs-primary-pallet, 500);
    font-size: 25px;
    line-height: 22px;
    right: 20px;
    top: 23px;
    font-weight: normal;
  }

  .accordion-heading[data-toggle='collapse'].collapsed:after {
    content: '\e5cf';
  }
}

.wa_workflows-edit {
  .recurring_days_checkboxes .checkbox-item-wrapper {
    flex: 0 1 10%;
  }

  .recurring_days_checkboxes input[type='checkbox'] + label {
    height: 28px !important;
    width: 28px !important;
    font-size: 12px;
  }
}

.onboarding_show,
.accounts-edit_show_user,
.accounts-show,
.wa_workflows-edit {
  .recurring_days_checkboxes input {
    display: none !important;
  }

  .recurring_days_checkboxes input[type='checkbox'] + label {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #666666;
    border: 1.145px solid #e0e0e0;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
    margin-right: 12px;
    background-color: #fafafa;
    font-size: 16px;
  }

  .flatpickr-input[readonly] {
    background: #fafafa !important;
    border: 1.145px solid #e0e0e0 !important;
    border-radius: 4px;
    font-weight: 500 !important;
    color: #3a3a3a !important;
  }

  .copy_time {
    position: absolute;
    top: 0;
    right: 30%;
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
      position: unset;
      justify-content: flex-end;
    }
  }

  .recurring_days_checkboxes input[type='checkbox']:checked + label {
    background: #4fd97f;
    border-color: #4fd97f;
    color: #ffffff;
    font-weight: 500;
    font-size: 16px;
  }
}

.sidecar-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 31;

  .sidecar {
    width: 450px;

    @include media-breakpoint-down(sm) {
      width: 100%;
    }

    background: white;
    height: 100%;
  }
}

.dropzone.dz-started .dz-message {
  position: relative;
  display: inline-block;
  // width: 120px;
  margin: 0.5em;
  min-height: 100px;
}

.pswp img {
  max-width: none;
  object-fit: contain;
}

.object-fit-cover {
  object-fit: cover;
}

.object-fit-contain {
  object-fit: contain;
}

label {
  color: #666666;
  margin-bottom: 0.2rem;
}
