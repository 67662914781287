@import 'bootstrap';
@import './colors.scss';
@import './gumstack_variables';
@import './_helper';
@import 'profile.scss';
@import './social_share_buttons.scss';
$flagsImagePath: '~intl-tel-input/build/img/';
@import '~intl-tel-input/src/css/intlTelInput';
@import './common.scss';
@import './validations.scss';
@import './slot_picker.scss';
@import './font_helpers';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import './onboarding.scss';
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;700&display=swap');

.gm-plug .title:before {
  position: absolute;
  left: 10;
  top: 15px;
  height: 0;
  width: 200px;
  content: '';
  border-top: 2px solid #ccc;
}

.gm-plug .title {
  margin-top: 20px;
}

.gm-plug p {
  font-size: 0.9rem;
  font-style: italic;
  color: #888;
}

@media (min-width: 1280px) {
  .simple-plug {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
  }
}

.simple-plug .title {
  //	margin-top: 20px;
}

.simple-plug .text {
  font-size: 0.9rem;
  font-style: italic;
  color: #888;
}

.slot {
  //	padding: 0.5em 1em;
  color: #ffffff;
  margin: 0.1em;
  text-align: center;
}

body {
  // background-color: #f4f5fa;
}

.host-details {
  display: flex;
  flex-direction: column;
  align-items: center;

  img.avatar {
    border-radius: 50%/50%;
  }
}

.content-box {
  background: #ffffff;
  margin: 1em 0;
  border: 1px solid rgba(77, 80, 85, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}

.content {
  background: #ffffff;
  margin: 1em 0;
  border: 1px solid rgba(77, 80, 85, 0.1);
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.08);
}

.content-box img {
  width: 100%;
}

.box-parent {
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.session-details {
  margin: 1em 0 2em 1em;
  max-height: 300px;
  width: 100%;
  border-radius: 15px;
  background: #fef5e5;
  overflow-y: scroll;
}

@media (min-width: 1025px) {
  .box-parent {
    //		height: 100vh;
  }
}

.border-right {
  border-color: #00b246;
}

.page-title {
  margin-top: 2em;
}

.razorpay-payment-button {
  background: linear-gradient(180deg, #6371c7, #5563c1);
  border-color: #5d58c5;
  width: 100%;
  padding: 8px;
  color: #ffffff;
  box-shadow: inset 0 1px 0 0 #6774c8, 0 1px 0 0 rgba(22, 29, 37, 0.05),
    0 0 0 0 transparent;
}

.razorpay-payment-button:hover,
.razorpay-payment-button:active,
.razorpay-payment-button:focus {
  background-color: #7564a8;
  border-color: #32017c;
  //	background: #4740B1;
  color: #ffffff;
  //	border-color: #5D58C5;
  box-shadow: none;
}

.alert-warning {
  padding: 1.25em 1.25em 0.75em 1.25em;
  color: #333;
  background-color: #d7e5ff;
  border-color: #d7d9ff;
}

.slots-list {
  max-height: 460px;
  overflow-x: hidden;
  overflow-y: auto;
}

.slot-confirm {
  display: flex;
}

.slot-confirm a.btn {
  flex: 1;
}

.slot a.btn {
  border-color: #3f4eae;
  background-color: #fff;
}

.slot a.btn:hover {
  background: linear-gradient(180deg, #5c6ac4, #4959bd);
  border-color: #3f4eae;
  //	background-color: #62CAA4;
  color: #fff;
}

.slot-ready a.btn {
  width: 100%;
  padding: 0.8em;
  font-size: 0.9em;
}

.slot-confirm a.btn {
  font-size: 0.9em;
}

.slot-confirm a.btn:first-child {
  margin-right: 1px;
  background-color: #3e3e40;
  color: #fff;
}

.slot-confirm a.btn:first-child:hover {
  background-color: #313131;
  color: #fff;
}

.slot-confirm a.btn:last-child {
  margin-left: 1px;
  background: linear-gradient(180deg, #5c6ac4, #4959bd);
  border-color: #3f4eae;
  color: #fff;
}

.slot-confirm a.btn:last-child:hover {
  background-color: #3f4eae;
}

.simple-calendar {
  max-width: 100%;

  .calendar-heading {
    text-align: center;

    span,
    a {
      margin-right: 10px;
      font-weight: bold;

      &:first-child {
        font-weight: normal;
      }

      &:last-child {
        font-weight: normal;
      }
    }
  }
}

.simple-calendar .day.current-month a,
.simple-calendar.current-month .day span.unlinked {
  background: #705b9e;
  border-radius: 50px;
  padding: 10px;
}

.simple-calendar .day.current-month a {
  color: #fff;
  font-weight: bold;
  min-width: 30px;
  display: block;
  text-align: center;
}

.simple-calendar .day.current-month a:hover {
  text-decoration: none;
}

.simple-calendar .day.current-month.start-date a {
  background: #ba34e8;
  color: #fff;
}

.simple-calendar .table td {
  padding: 0.5rem 0.5rem;
  text-align: center;
  vertical-align: middle;
}

.subtext {
  font-size: 0.9em;
  color: #666;
}

.info {
  padding: 1em 0;
  background-color: #defef3;
  margin-bottom: 1em;
  border-radius: 10px;
}

.text-small {
  font-size: 0.9em;
}

@media (max-width: 480px) {
  .table {
    font-size: 0.9rem;
  }
}

@media (max-width: 375px) {
  .simple-calendar {
    .table {
      font-size: 0.8rem;

      th {
        padding: 0;
      }

      td {
        padding: 0.5rem 0;
      }
    }
  }
}

@media (min-width: 1025px) {
  .content-box {
    //    max-height: 580px;
    margin: none;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 28px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #5563c1;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(24px);
  -ms-transform: translateX(24px);
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px;
}

.slider.round:before {
  border-radius: 50%;
}

.slot-picker--cell {
  height: 2.5rem;
  font-weight: 500;
  margin-bottom: 10px;
  margin: 0.5rem 0.7rem;

  @media (max-width: 768px) {
    margin: 0.15rem 0.15rem;
  }
}

a.slot-picker--cell {
  border: 1px solid #705b9e;
  border-radius: 50px;
  color: #705b9e;
  transition: 0.2s;

  &:hover {
    background: #705b9e;
    color: white;
    text-decoration: none;
  }
}

.modal-backdrop {
  z-index: auto;
}

.loading-spinner {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(240, 240, 240, 0.6);
}

.section {
  @extend #{ '.bg-white',
    '.m-md-2',
    '.px-2',
    '.py-3',
    '.px-md-5',
    '.py-md-4',
    '.rounded' };
  display: flex;
  flex-direction: column;
  z-index: 1030;
}

.section-scroll {
  @extend #{ '.m-md-2' };
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
  background: white;
}

.bottom-0 {
  bottom: 0;
}

.actions {
  position: fixed;
  bottom: 32px;
  width: 75%;
}

.invites_new {
  font-family: Nunito !important;
  background: #fff !important;

  .form-control {
    outline: none;
    appearance: none;
    box-shadow: none;
    border: 1px solid #e0e0e0;
    padding: 1.3rem;
  }

  ::placeholder {
    color: #a3a3a3 !important;
  }

  .btn-primary {
    background: #5f6fbf !important;
    color: white !important;
    border-color: #5f6fbf !important;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    font-weight: bold;
    padding: 0.7rem 2rem;
  }

  .line {
    width: 1px;
    height: 60%;
    background: #e0e0e0;
  }
}

.progressbar li {
  list-style-type: none;
  width: 33%;
  float: left;
  font-size: 22px;
  position: relative;
  text-align: center;
  color: #ffffff;
  opacity: 0.3;

  &.current {
    font-weight: 500;
  }

  &.active {
    color: #ffffff !important;
    opacity: 1;
  }
}

.progressbar li:before {
  width: 36px;
  height: 36px;
  // content: counter(step);
  // counter-increment: step;
  line-height: 36px;
  border: 1px solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  font-weight: bold;
}

.current::before {
  background-color: #545ea3 !important;
  color: white;
}

.progressbar li:after {
  width: 64%;
  content: '';
  position: absolute;
  top: 16px;
  left: -32%;
  // z-index: -1;
  border-top: 2px dashed #fff;
}

.progressbar li.active:after {
  color: white;
  width: 64%;
  content: '';
  position: absolute;
  top: 16px;
  left: -32%;
  // z-index: -1;
  border-top: 2px dashed #fff;
  opacity: 1;
}

.progressbar li:first-child:after {
  content: none;
}

.progressbar li.unfinished a {
  color: #3a3a3a;
}

.progressbar li.active,
.progressbar li.active a,
.progressbar li.current a {
  color: #ffffff;
}

// .progressbar li.active:before {
//   border-color: #51a34a;
//   background-color: #51a34a;
//   color: #ffffff;
//   content: '✓';
// }
.bg-primary {
  background-color: #202e78 !important;
}

.post-actions {
  bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  span {
    border-radius: 1rem;
  }
}

.container-fixed {
  max-width: 960px !important;
}

#editor-content {
  img {
    max-width: 100%;
  }

  margin-bottom: 50px;
}

$primary-color: #4ed97f;
$primary-color-hover: #27c05d;
$secondary-color: #d5dee9;
$font-color: #262d34;
$font-color-inverse: #ffffff;
$border-radius: 4px;

@mixin button-styles($background-color, $border-color, $font-color) {
  background: $background-color;
  border: 1px solid $border-color;
  color: $font-color;
  border-radius: $border-radius;
  font-weight: 500;
}

.bg-primary {
  background-color: #4ed97f !important;
}

.btn-primary {
  @include button-styles($primary-color, $primary-color-hover, $font-color);

  &:hover,
  &:focus,
  &:active,
  &:disabled {
    @include button-styles(
      $primary-color-hover,
      $primary-color-hover,
      $font-color
    );
  }

  &:disabled {
    @include button-styles($primary-color, $primary-color, $font-color-inverse);
  }
}

.btn-outline-primary {
  @include button-styles($font-color-inverse, $primary-color, $font-color);

  &:hover,
  &:focus,
  &:active {
    @include button-styles($primary-color, $primary-color, $font-color);
  }
}

.btn-secondary {
  @include button-styles(
    $secondary-color,
    $secondary-color,
    $font-color-inverse
  );

  &:hover,
  &:focus,
  &:active {
    @include button-styles(
      $secondary-color,
      $secondary-color,
      $font-color-inverse
    );
  }
}

.btn-outline-secondary {
  @include button-styles($font-color-inverse, $secondary-color, $font-color);

  &:hover,
  &:focus,
  &:active {
    @include button-styles($secondary-color, $secondary-color, $font-color);
  }
}

.link-primary {
  color: $primary-color;
}

.link-primary:hover {
  color: $primary-color-hover;
}

.btn-primary-800 {
  background-color: #e8eff5;
}

.nav-pills {
  background: #ebf1f7;
  border-radius: 0.6rem;
  padding: .4125rem;

  .nav-link {
      padding: .75rem;
      color: #293951;
      font-size: 14px;

      &.active {
        box-shadow: 0 1px 3px rgba(0, 0, 0, .05);
        color: #293951;
        background-color: #ffffff;
        font-weight: bold;
      }
  }
}